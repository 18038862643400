<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }"
                >首页</el-breadcrumb-item
            >
            <el-breadcrumb-item>IBC赛务管理</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 个人赛赛程 -->
        <el-card style="margin-top: 15px">
            <el-row class="title_row">
                <el-col>
                    <span class="title_class">IBC报名信息</span>
                </el-col>
            </el-row>
            <div class="schedule-list">
                <div
                    class="schedule-item"
                    v-for="(itemData, index) in scheduleCardList"
                    v-bind:key="index"
                >
                    <gameStageBackview
                        :itemData="itemData"
                        :key="index"
                        :itemIndex="index"
                        @itemClicked="scheduleCardTap"
                    ></gameStageBackview>
                </div>
            </div>
        </el-card>
        <!-- IBC考务工具 -->
        <el-card style="margin-top: 15px">
            <el-row class="title_row">
                <el-col>
                    <span class="title_class">IBC赛务工具</span>
                </el-col>
            </el-row>
            <div class="schedule-list" style="margin-top: 34px">
                <div
                    class="schedule-item"
                    v-for="(item, i) of racingToolList"
                    v-bind:key="i"
                >
                    <gameToolBackView
                        :itemData="item"
                        :key="i"
                        @itemClicked="racingToolClicked"
                    ></gameToolBackView>
                </div>
            </div>
        </el-card>
        <!-- 重置选手成绩 -->
        <el-dialog
            title="重置选手成绩"
            :visible.sync="gameTypeChangePoster"
            width="460px"
            @close="editGameTypeDialogClose"
        >
            <el-form
                :model="editGameTypeForm"
                label-width="80px"
                ref="editGameTypeFormRef"
                :rules="editGameTypeFormRules"
            >
                <el-form-item label="届" prop="ibcSession">
                    <el-select
                        placeholder="请选择"
                        style="width: 100%"
                        v-model="editGameTypeForm.ibcSession"
                        clearable
                    >
                        <el-option
                            v-for="item in dict_ibcSession"
                            :key="item.dictCode"
                            :label="item.dictLabel"
                            :value="item.dictValue"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="季度" prop="ibcSeason">
                    <el-select
                        placeholder="请选择"
                        style="width: 100%"
                        v-model="editGameTypeForm.ibcSeason"
                        clearable
                    >
                        <el-option
                            v-for="item in dict_ibcSeason"
                            :key="item.dictCode"
                            :label="item.dictLabel"
                            :value="item.dictValue"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="轮次" prop="ibcRound">
                    <el-select
                        placeholder="请选择"
                        style="width: 100%"
                        v-model="editGameTypeForm.ibcRound"
                        disabled
                        clearable
                    >
                        <el-option
                            v-for="item in dict_ibcRound"
                            :key="item.dictCode"
                            :label="item.dictLabel"
                            :value="item.dictValue"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="手机号" prop="phone">
                    <el-input
                        v-model="editGameTypeForm.phone"
                        placeholder="请输入手机号"
                        type="text"
                        clearable
                    ></el-input>
                </el-form-item>
                <el-form-item label="修改原因" prop="remark">
                    <el-input
                        v-model="editGameTypeForm.remark"
                        placeholder="请输入"
                    ></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="gameTypeChangePoster = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="editGameTypeScore"
                    >修 改</el-button
                >
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { resetMatchScore } from '@/http/api'
export default {
  data () {
    return {
      dict_ibcSeason: this.$userInfo.getDataList(this, 'ibc_season').then(value => { this.dict_ibcSeason = value }),
      dict_ibcSession: this.$userInfo.getDataList(this, 'ibc_session').then(value => { this.dict_ibcSession = value }),
      dict_ibcRound: this.$userInfo.getDataList(this, 'ibc_round').then(value => { this.dict_ibcRound = value }),
      editGameTypeForm: {
        activityType: this.$chnEngStatusCode.ibcActivityType,
        phone: '',
        ibcRound: '1',
        remark: '',
        ibcSession: '',
        ibcSeason: ''
      },
      editGameTypeFormRules: {
        phone: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
        ibcSession: [{ required: true, message: '请选择', trigger: 'change' }],
        ibcSeason: [{ required: true, message: '请选择', trigger: 'change' }],
        ibcRound: [{ required: true, message: '请选择', trigger: 'change' }],
        remark: [{ required: true, message: '请输入备注信息', trigger: 'blur' }]
      },
      scheduleCardList: [
        {
          scheduleName: '第一季',
          scheduleId: 1,
          backGround: '#57a3f7',
          flag: this.$chnEngStatusCode.ibc_register_info
        },
        {
          scheduleName: '第二季',
          scheduleId: 2,
          backGround: '#57a3f7',
          flag: this.$chnEngStatusCode.ibc_register_info
        },
        {
          scheduleName: '第三季',
          scheduleId: 3,
          backGround: '#57a3f7',
          flag: this.$chnEngStatusCode.ibc_register_info
        },
        {
          scheduleName: '第四季',
          scheduleId: 4,
          backGround: '#57a3f7',
          flag: this.$chnEngStatusCode.ibc_register_info
        }
      ],
      gameTypeChangePoster: false,
      racingToolList: [
        {
          rightTitle: '重置成绩',
          rightDesc: '重置选手第一轮线上成绩',
          icon: 'icon-bg-reset',
          flag: this.$chnEngStatusCode.reset_player_score,
          path: '/raceManagement/ibc/reset_player_score',
          menuTag: '1'
        },
        {
          rightTitle: '轮次管理',
          rightDesc: '新增/修改轮次内容',
          icon: 'icon-dkw_shenhezhong',
          flag: this.$chnEngStatusCode.ibc_round_info,
          path: '/raceManagement/ibc/ibc_round_info',
          menuTag: '3'
        },
        {
          rightTitle: '考场管理',
          rightDesc: '设置/新增线上考场内容',
          icon: 'icon-jigou',
          flag: this.$chnEngStatusCode.ibc_examroom_info,
          path: '/raceManagement/ibc/ibc_examroom_info',
          menuTag: '2'
        }, {
          rightTitle: '成绩设置',
          rightDesc: '设置是否晋级',
          flag: this.$chnEngStatusCode.ibc_score_info,
          icon: 'icon-cuoqingdengji',
          path: '/raceManagement/ibc/ibc_score_info',
          menuTag: '4'
        },
        {
          rightTitle: '成绩查询',
          rightDesc: '查询/公布选手轮次成绩',
          dialogTitle: '免初赛管理',
          icon: 'icon-baoming',
          flag: this.$chnEngStatusCode.ibc_searchscore_info,
          path: '/raceManagement/ibc/ibc_searchscore_info',
          menuTag: '5'
        }
      ]
    }
  },
  created () {
  },
  methods: {
    // 报名季度点击事件
    scheduleCardTap (scheduleData) {
      this.$router.push(
        {
          path: '/raceManagement/ibc/ibc_register_info',
          query: {
            ibcSeason: scheduleData.scheduleId
          }
        }
      )
    },
    // 重置第一轮成绩
    editGameTypeScore () {
      this.$refs.editGameTypeFormRef.validate(async valid => {
        if (!valid) return
        resetMatchScore(this.editGameTypeForm).then((res) => {
          this.gameTypeChangePoster = false
          return this.$message.success('重置成功')
        }).catch((err) => {
          console.log('err', err)
        })
      })
    },
    // 修改参赛方式关闭
    editGameTypeDialogClose () {
      this.$refs.editGameTypeFormRef.resetFields()
    },
    // 考务工具点击
    racingToolClicked (item) {
      console.log('item', item)
      switch (item.flag) {
        case 'reset_player_score': // 重置选手成绩
          this.gameTypeChangePoster = true
          break
        default:
          this.$router.push(
            {
              path: item.path
            }
          )
          break
      }
    }
  }
}

</script>

<style lang="less" scoped>
.schedule_card {
    width: 100%;
    height: 54px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.schedule_gameName {
    text-align: left;
    color: white;
    font-size: 20px;
    margin-bottom: 6px;
}

.schedule_gameNumber {
    text-align: left;
    color: white;
    font-size: 12px;
    margin-left: 20px;
}

/* .racing-tool-back {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.racing-tool-leftimg {
  width: 50px;
  height: 60px;
  margin-right: 5px;
}
.racing-tool-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 16px;
  font-weight: 900;
  color: rgb(107, 107, 107);
}
.racing-tool-desc {
  font-weight: 400;
  font-size: 12px;
} */

.statistics-data-percent-history {
    display: flex;
    align-items: center;
}

.statistics-data-percent-history .icon-data {
    margin-bottom: 0;
    margin-right: 12px;
}
</style>
